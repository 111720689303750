import { compact, get } from 'lodash';
import { getBedLengthValue } from 'site-modules/shared/utils/features-format';
import { isPickupType } from 'site-modules/shared/utils/is-pickup-type';
import { toSentenceCase } from 'client/utils/string-utils';

export function getEngineAttr({ vehicleInfo, showElectricityRange, isUsedVinType, isShop3165Enabled }) {
  const electricityRange = get(vehicleInfo, 'partsInfo.electricityRange', '');

  if (showElectricityRange) {
    const rangeLabel = isShop3165Enabled && isUsedVinType ? ' mi range' : 'mi EPA electric range';
    return electricityRange ? `${electricityRange}${rangeLabel}` : '';
  }

  const bodyType = get(vehicleInfo, 'styleInfo.bodyType');
  const isPickup = isPickupType(bodyType);
  const engineSize = get(vehicleInfo, 'partsInfo.engineSize');
  const cylinders = get(vehicleInfo, 'partsInfo.cylinders');
  const transmission = get(vehicleInfo, 'partsInfo.transmission');
  const bedLength = getBedLengthValue(get(vehicleInfo, 'partsInfo.bedLengthMeasurement'));

  const engineAttr = compact([
    isPickup && bedLength && `${bedLength} Bed`,
    isPickup && engineSize && `${engineSize}L`,
    cylinders && `${cylinders}cyl`,
    !isPickup && transmission,
  ]).join(isPickup && bedLength ? ', ' : ' ');

  return isShop3165Enabled ? toSentenceCase(engineAttr) : engineAttr;
}
