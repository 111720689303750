import React from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { connect } from 'react-redux';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { AdsModel } from 'client/data/models/ads';
import { AdUnit } from 'client/site-modules/shared/components/ad-unit/ad-unit';
import { PartnerPromoAd } from 'client/site-modules/shared/components/native-ad/partner-promo-ad/partner-promo-ad';
import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import './partner-promotions.scss';

const DEFAULT_MODULE_TITLE = 'Partner promotions';

export function PartnerPromotionsUI({ partnerPromoContent, isMobile, cols, showSeparators }) {
  if (
    !partnerPromoContent ||
    !(partnerPromoContent.partnerPromoAds && partnerPromoContent.partnerPromoAds.length > 0)
  ) {
    return null;
  }

  const { partnerPromoAds, title = DEFAULT_MODULE_TITLE } = partnerPromoContent;

  const partnerPromoAdsToRender = partnerPromoAds.filter(
    ({ desktopHide, mobileHide }) => !(!isMobile && desktopHide) && !(isMobile && mobileHide)
  );

  return (
    <div className="partner-promos p-0 pr-lg-1 pr-xl-2" data-tracking-parent="edm-entry-partner-promotions">
      {showSeparators && <hr className="mt-0 mb-2" />}
      <div className="header-wrapper pos-r">
        <h2 className="text-capitalize heading-3 text-start pl-0_5 mb-1_25 mb-md-2 text-lowercase promo-title">
          {getTitleWithNoOrphans(title)}
        </h2>
      </div>

      <Row>
        {partnerPromoAdsToRender.map((ad, index) => (
          <Col
            {...cols}
            className="partner-promo-ad mb-2"
            key={`partner-promo-${index + 1}`}
            data-tracking-id="home_page_type_select"
            data-tracking-value={ad && ad.copy}
          >
            <PartnerPromoAd content={ad} isMobile={isMobile} />
            <AdUnit
              adName={isMobile ? 'mpartnerpromo' : 'partnerpromo'}
              position={`${index + 1}`}
              all
              oneByOne
              renderWhenViewable={false}
            />
          </Col>
        ))}
      </Row>
      {showSeparators && <hr className="mt-0_25 mb-0 hidden-md-up" />}
    </div>
  );
}

PartnerPromotionsUI.propTypes = {
  partnerPromoContent: PropTypes.shape({}),
  isMobile: PropTypes.bool,
  cols: PropTypes.shape({}),
  showSeparators: PropTypes.bool,
};

PartnerPromotionsUI.defaultProps = {
  partnerPromoContent: null,
  isMobile: false,
  cols: { xs: 6, lg: 4 },
  showSeparators: true,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const PartnerPromotions = connect(mapStateToProps)(
  connectToModel(PartnerPromotionsUI, {
    partnerPromoContent: bindToPath('partnerPromotionsAd', AdsModel),
  })
);
