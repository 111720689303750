import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { times } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { AdUnit } from 'client/site-modules/shared/components/ad-unit/ad-unit';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';
import { FeaturedSpotlightAd } from 'client/site-modules/shared/components/native-ad/featured-spotlight-ad/featured-spotlight-ad';

import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';

import './featured-spotlight-carousel.scss';

const carouselSettings = {
  speed: 500,
  arrows: false,
};

const AD_PIXELS_COUNT = 3;

function FeaturedSpotlightCarouselUI({ slides, isMobile, className }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  if (!slides || slides.length === 0) {
    return null;
  }

  function onBeforeChange(from, to) {
    setCurrentSlide(to);
  }

  function customPaging(i) {
    return (
      <button aria-pressed={currentSlide === i}>
        <span className="visually-hidden">{slides[i].headline}</span>
      </button>
    );
  }

  return (
    <FeatureFlag name="ads">
      <div data-tracking-parent="featured-spotlight" className={classnames('featured-spotlight-carousel', className)}>
        <ClientSideRender placeholder={<FeaturedSpotlightAd content={slides[0]} isMobile={isMobile} />}>
          <Carousel
            {...carouselSettings}
            dots={slides.length > 1}
            customPaging={customPaging}
            beforeChange={onBeforeChange}
          >
            {slides.map((slide, index) => (
              <FeaturedSpotlightAd
                content={slide}
                isMobile={isMobile}
                id={`featured-spotlight-slide-${index + 1}`}
                key={`featured-spotlight-slide-${index + 1}`}
              />
            ))}
          </Carousel>
        </ClientSideRender>
        {times(AD_PIXELS_COUNT, index => (
          <div key={`featuredspor-${index + 1}`} className="hidden">
            <AdUnit
              adName={isMobile ? 'mfeaturedspot' : 'featuredspot'}
              position={`${index + 1}`}
              all
              oneByOne
              renderWhenViewable={false}
            />
          </div>
        ))}
      </div>
    </FeatureFlag>
  );
}

FeaturedSpotlightCarouselUI.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      headline: PropTypes.string,
      url: PropTypes.string,
      urlText: PropTypes.string,
      urlMobile: PropTypes.string,
      oemLink: PropTypes.string,
      urlTargetNew: PropTypes.string,
      image: PropTypes.string,
      contentLabel: PropTypes.string,
      adLabel: PropTypes.string,
      disclaimer: PropTypes.string,
    })
  ),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

FeaturedSpotlightCarouselUI.defaultProps = {
  slides: [],
  isMobile: false,
  className: null,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const FeaturedSpotlightCarousel = connect(mapStateToProps)(FeaturedSpotlightCarouselUI);
