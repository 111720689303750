import React from 'react';
import PropTypes from 'prop-types';
import Col from 'reactstrap/lib/Col';
import { Link } from 'site-modules/shared/components/link/link';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

export function ShopByTypeCard({ title, trackingValue, path, icon }) {
  return (
    <Col tag="li" className="px-1 px-md-1_5 px-lg-2_5">
      <Link
        to={`/${path}/`}
        className="h-100 d-flex flex-column align-items-center"
        data-tracking-id="home_page_type_select"
        data-tracking-value={trackingValue}
      >
        <AspectRatioContainer aspectRatio="100:37" className="w-100">
          <img src={getStaticImageUrl(icon)} decoding="async" alt="" className="icon" />
        </AspectRatioContainer>
        <span className="fw-bold size-16 text-gray-darkest mt-0_75">{title}</span>
      </Link>
    </Col>
  );
}

ShopByTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  trackingValue: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
