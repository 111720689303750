import { maxBy, min, get } from 'lodash';
import { CAR_TYPES } from 'site-modules/shared/constants/upper-funnel/car-types';
import { TYPES_WITH_MOST_SOLD_GRAPH } from 'site-modules/shared/components/upper-funnel/most-sold-type-infographic/constants';
import { getUrlSlugByName } from './type-mapping';

const SUBTYPE_EXPANSION_URLS = {
  '/convertible/cars/#luxury-convertibles': '/best/luxury-convertible/',
  '/coupe/sports-cars/#coupes': '/best/sport-coupe/',
  '/hatchback/small/#x-small-hatchbacks': '/best/subcompact-hatchback/',
  '/hybrid/luxury/#luxury-plug-in-hybrid-cars': '/best/luxury-plug-in-hybrid/',
  '/sedan/small/#small-sedans': '/best/compact-sedan/',
  '/sedan/luxury/#small-luxury-sedans': '/best/luxury-compact-sedan/',
  '/sedan/luxury/#large-luxury-sedans': '/best/large-luxury-sedan/',
  '/sedan/luxury/#midsize-luxury-sedans': '/best/midsize-luxury-sedan/',
  '/suv/small/#small-3-row-suvs': '/best/compact-3-row-suv/',
  '/suv/luxury/#small-luxury-suvs': '/best/compact-luxury-suv/',
  '/suv/3-row/#large-suvs': '/best/large-3-row-suv/',
  '/suv/luxury/#large-luxury-suvs': '/best/large-luxury-suv/',
  '/suv/hybrid/#luxury-plug-in-hybrid-suvs': '/best/luxury-plug-in-hybrid-suv/',
  '/suv/small/#x-small-suvs': '/best/subcompact-suv/',
  '/suv/small/#x-small-luxury-suvs': '/best/luxury-subcompact-suv/',
  '/suv/luxury/#super-luxury-suvs': '/best/super-luxury-suv/',
  '/electric-car/#electric-vans': '/best/electric-van/',
  '/electric-car/luxury-electric-cars/#performance-electric-cars': '/best/performance-electric-car/',
  '/convertible/cars/#exotic-sport-convertibles': '/best/exotic-convertible/',
  '/convertible/cars/#luxury-sport-convertibles': '/best/luxury-sport-convertible/',
  '/coupe/sports-cars/#luxury-sport-coupes': '/best/luxury-sport-coupe/',
  '/sedan/sport/#sport-compacts': '/best/compact-sports-sedan/',
  '/sedan/sport/#midsize-performance-sedans': '/best/midsize-performance-sedan/',
  '/electric-car/luxury-electric-suvs/#performance-electric-suvs': '/best/performance-electric-suv/',
  '/suv/luxury/#midsize-performance-suvs': '/best/performance-midsize-suv/',
  '/electric-car/#all-wheel-drive-electric-trucks': '/best/awd-electric-truck/',
  '/crossover/#midsize-luxury-crossovers': '/best/midsize-luxury-crossover/',
};

const DISPLAY_NAME_MAP = {
  [CAR_TYPES.SUV]: {
    'x-small-suvs': 'small',
    'small-suvs': 'small',
    'small-3-row-suvs': 'small',
    'midsize-suvs': 'midsize',
    'midsize-3-row-suvs': '3-row',
    'large-suvs': 'large',
    'x-small-luxury-suvs': 'luxury',
    'small-luxury-suvs': 'luxury',
    'midsize-luxury-suvs': 'luxury',
    'midsize-3-row-luxury-suvs': 'luxury',
    'large-luxury-suvs': 'luxury',
    'super-luxury-suvs': 'luxury',
    'small-performance-suvs': 'luxury',
    'midsize-performance-suvs': 'luxury',
    'large-performance-suvs': 'luxury',
    'hybrid-suvs': 'hybrid',
    'luxury-electric-suvs': 'electric',
    'luxury-plug-in-hybrid-suvs': 'hybrid',
    'plug-in-hybrid-suvs': 'hybrid',
    'electric-suvs': 'electric',
    '3-row-suvs': '3-row',
  },
  [CAR_TYPES.SEDAN]: {
    'x-small-sedans': 'small',
    'small-sedans': 'small',
    'midsize-sedans': 'midsize',
    'large-sedans': 'large',
    'x-small-luxury-sedans': 'luxury',
    'small-luxury-sedans': 'luxury',
    'midsize-luxury-sedans': 'luxury',
    'large-luxury-sedans': 'luxury',
    'exotic-sedans': 'luxury',
    'sport-compacts': 'sport',
    'small-performance-sedans': 'sport',
    'midsize-performance-sedans': 'sport',
    'large-performance-sedans': 'sport',
  },
  [CAR_TYPES.TRUCK]: {
    'small-trucks': 'small',
    'midsize-trucks': 'midsize',
    'large-trucks': 'large',
    'heavy-duty-trucks': 'heavy-duty',
  },
  [CAR_TYPES.WAGON]: {
    'small-wagons': 'small',
    'midsize-wagons': 'midsize',
  },
  [CAR_TYPES.COUPE]: {
    coupes: 'sports-cars',
    'muscle-cars': 'muscle-cars',
    'super-muscle-cars': 'muscle-cars',
    'luxury-coupes': 'luxury',
    'luxury-sport-coupes': 'sports-cars',
    'exotic-coupes': 'luxury',
    'exotic-sport-coupes': 'sports-cars',
  },
  [CAR_TYPES.CONVERTIBLE]: {
    convertibles: 'cars',
    'luxury-convertibles': 'cars',
    'luxury-sport-convertibles': 'cars',
    'exotic-convertibles': 'cars',
    'exotic-sport-convertibles': 'cars',
  },
  [CAR_TYPES.HATCHBACK]: {
    'x-small-hatchbacks': 'small',
    'small-hatchbacks': 'small',
    'hot-hatches': 'hot-hatches',
  },
  [CAR_TYPES.ELECTRIC]: {
    'luxury-electric-cars': 'luxury-electric-cars',
    'luxury-all-wheel-drive-electric-cars': 'luxury-electric-cars',
    'super-luxury-electric-cars': 'luxury-electric-cars',
    'super-luxury-all-wheel-drive-electric-cars': 'luxury-electric-cars',
    'performance-electric-cars': 'luxury-electric-cars',
    'exotic-performance-electric-cars': 'luxury-electric-cars',
    'luxury-electric-suvs': 'luxury-electric-suvs',
    'luxury-all-wheel-drive-electric-suvs': 'luxury-electric-suvs',
    'super-luxury-electric-suvs': 'luxury-electric-suvs',
    'super-luxury-all-wheel-drive-electric-suvs': 'luxury-electric-suvs',
    'performance-electric-suvs': 'luxury-electric-suvs',
    'exotic-performance-electric-suvs': 'luxury-electric-suvs',
  },
  [CAR_TYPES.HYBRID]: {
    'plug-in-hybrid-cars': 'plug-in-hybrid-cars',
    'plug-in-hybrid-suvs': 'plug-in-hybrid-suvs',
    'luxury-hybrids': 'luxury',
    'luxury-plug-in-hybrid-cars': 'luxury',
    'luxury-plug-in-hybrid-suvs': 'luxury',
  },
};

export const ELECTRIC_HYBRID_SUV_EXCEPTION_MAP = {
  'electric-suvs': 'suv/electric',
  'all-wheel-drive-electric-suvs': 'suv/electric',
  'hybrid-suvs': 'suv/hybrid',
  'all-wheel-drive-electric-cars': 'electric-car/awd',
};

export const ALWAYS_OPENED_MAP = ['electric-cars'];

export function getAnchorName(displayName) {
  return `${displayName
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')}`;
}

/**
 * Builds the link to type-rankings page with anchor to subsegment
 *
 * @param typeName
 * @param subtypeDisplayName See client/data/models/type-rankings.js to get the correct subtype displayName
 * @param enableMarketBrewTestBestType
 * @return {string}
 */
export function buildRankingsSubsegmentLink(typeName, subtypeDisplayName) {
  return `/${getUrlSlugByName(typeName)}/#${getAnchorName(subtypeDisplayName)}`;
}

export function buildSubtypeSubsegmentLink(edmundsTypeCategory, displayName, enableSubtypeExpansion) {
  const isSUVCategory =
    edmundsTypeCategory.toLowerCase() === 'suv' ||
    ['hybrid-suvs', 'luxury-electric-suvs', 'luxury-plug-in-hybrid-suvs'].includes(getAnchorName(displayName));

  const typeName = isSUVCategory ? 'suv' : edmundsTypeCategory.toLowerCase();
  const anchorName = getAnchorName(displayName);
  let subtypeSubsegmentLink = null;

  if (ELECTRIC_HYBRID_SUV_EXCEPTION_MAP[anchorName]) {
    subtypeSubsegmentLink = `/${ELECTRIC_HYBRID_SUV_EXCEPTION_MAP[anchorName]}/#${anchorName}`;
  } else if (DISPLAY_NAME_MAP[typeName] && DISPLAY_NAME_MAP[typeName][anchorName]) {
    subtypeSubsegmentLink = `/${getUrlSlugByName(typeName)}/${DISPLAY_NAME_MAP[typeName][anchorName]}/#${anchorName}`;
  }

  return enableSubtypeExpansion
    ? get(SUBTYPE_EXPANSION_URLS, subtypeSubsegmentLink, subtypeSubsegmentLink)
    : subtypeSubsegmentLink;
}

const FIRST_GENERATION = 1;
export function getGenerationText(generations) {
  if (!(generations && generations.length)) {
    return '';
  }
  const { generationYears, generation } = maxBy(generations, 'generation');
  return `${generation === FIRST_GENERATION ? 'Introduced' : 'Redesigned'} in ${min(generationYears)}`;
}

export function hasSubsegments(vehicleEditorialRankings) {
  return !!(vehicleEditorialRankings && vehicleEditorialRankings.some(({ subsegment }) => subsegment));
}

export function hasMostSoldTypeContent(type, subtype) {
  const mostSoldTypeUniqueName = `${subtype}-${type}s`.toLowerCase();
  return TYPES_WITH_MOST_SOLD_GRAPH.includes(mostSoldTypeUniqueName);
}

export const getDisplayName = (isSUVCategory, displayName) => {
  if (isSUVCategory) {
    return displayName
      .toLowerCase()
      .replace('suv', 'SUV')
      .replace('luxury electric', 'electric');
  }
  return displayName.toLowerCase().replace('suv', 'SUV');
};
