import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { flow, isNil } from 'lodash';
import { ApiMetrics, withMetrics } from 'client/utils/metrics-hoc';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  LLM_DATA_STATUSES,
  PersonalizedSearchModel,
  PersonalizedSearchPaths,
} from 'client/data/models/personalized-search';
import { venomHistory } from 'client/utils/history/venom-history';
import { randomInt } from 'client/utils/seed-randomizers';
import { getVdpLink } from 'site-modules/shared/utils/inventory/usurp-inventory-card';
import { getPersonalizedSearchRouteWithKeyParam } from 'site-modules/shared/utils/personalized-search/personalized-search-utils';
import { GlobalSearch } from 'site-modules/shared/components/inventory/global-search/global-search';
import { PersonalizeSearchLoadingScreen } from 'site-modules/shared/components/personalized-search/personalized-search-loading-screen/personalized-search-loading-screen';
import { fireSubmitTracking, fireWidgetViewTracking } from 'site-modules/shared/utils/inventory/global-search-tracking';
import { CREATIVE_ID, FOUND_VIN_SCREEN_CREATIVE_ID } from 'site-modules/shared/constants/global-search/global-search';

function PersonalizedSearchQueryUI({
  isMobile,
  setModelValue,
  userSearch,
  dataStatus,
  apiMetrics,
  withLlmSearchDisabled,
}) {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const skipFirstRender = useRef(false);

  useEffect(() => {
    if (dataStatus === LLM_DATA_STATUSES.ERROR) {
      setModelValue(PersonalizedSearchPaths.getDataStatus(), PersonalizedSearchModel, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (dataStatus === LLM_DATA_STATUSES.SUCCESS && userSearch?.searchId && skipFirstRender.current) {
      const foundVinInfo = userSearch?.struct?.vin?.identifiedVin?.vinInfo;

      if (!isNil(foundVinInfo)) {
        fireWidgetViewTracking({
          value: `srp_search_id=${userSearch.searchId}`,
          creativeId: FOUND_VIN_SCREEN_CREATIVE_ID,
        });

        venomHistory.push(getVdpLink({ vehicle: foundVinInfo, radius: 0 }));
      } else {
        fireSubmitTracking({
          input: userSearch.input,
          creativeId: CREATIVE_ID,
          searchId: userSearch.searchId,
        });

        venomHistory.push(getPersonalizedSearchRouteWithKeyParam(userSearch.searchId));
      }
    }

    skipFirstRender.current = true;
  }, [dataStatus, userSearch]);

  const triggerSubmit = useCallback(
    async submittedQuery => {
      if (submittedQuery) {
        await setModelValue(PersonalizedSearchPaths.getUserSearch(), PersonalizedSearchModel, {
          userSearch: submittedQuery,
          random: randomInt(),
        });
      }
    },
    [setModelValue]
  );

  const handleLoadingCancel = useCallback(() => {
    setIsLoading(false);
    setModelValue(PersonalizedSearchPaths.getLlmAborted(), PersonalizedSearchModel, true);
  }, [setModelValue]);

  const cancelLoadingForCachedPage = useCallback(({ persisted }) => {
    if (persisted) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('pageshow', cancelLoadingForCachedPage);

    return () => {
      window.removeEventListener('pageshow', cancelLoadingForCachedPage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataStatus === LLM_DATA_STATUSES.ERROR) {
      setIsLoading(false);
    }
    if (dataStatus === LLM_DATA_STATUSES.LOADING) {
      setIsLoading(true);
    }
    if (dataStatus === LLM_DATA_STATUSES.SUCCESS && userSearch?.searchId) {
      setIsLoading(false);
    }
  }, [dataStatus, userSearch]);

  const loadingComponent = (
    <PersonalizeSearchLoadingScreen
      isLoading={isLoading}
      isCancelDisabled={dataStatus === LLM_DATA_STATUSES.SUCCESS}
      onCancel={handleLoadingCancel}
      preserveLoadingScreen={dataStatus === LLM_DATA_STATUSES.SUCCESS}
    />
  );

  return (
    <GlobalSearch
      query={query}
      onSearchChange={setQuery}
      onSearchSubmit={triggerSubmit}
      loadingComponent={loadingComponent}
      apiMetrics={apiMetrics}
      isMobile={isMobile}
      isLoading
      isError={dataStatus === LLM_DATA_STATUSES.ERROR}
      withLlmSearchDisabled={withLlmSearchDisabled}
      searchId={userSearch?.searchId}
    />
  );
}

PersonalizedSearchQueryUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  apiMetrics: ApiMetrics.isRequired,
  dataStatus: PropTypes.string,
  userSearch: PropTypes.shape({}),
  isMobile: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
};

PersonalizedSearchQueryUI.defaultProps = {
  dataStatus: undefined,
  userSearch: undefined,
  isMobile: false,
  withLlmSearchDisabled: false,
};

export const PersonalizedSearchQuery = flow([
  withMetrics,
  component =>
    connectToModel(component, {
      userSearch: bindToPath(PersonalizedSearchPaths.getSearch(), PersonalizedSearchModel, null, false),
      dataStatus: bindToPath(PersonalizedSearchPaths.getDataStatus(), PersonalizedSearchModel, null, false),
    }),
])(PersonalizedSearchQueryUI);
