import React from 'react';
import PropTypes from 'prop-types';
import { get, compact } from 'lodash';

import { InventoryEntities } from 'client/data/models/inventory';
import { getDefaultStockPhotoBySize, SOURCES } from 'client/utils/image-helpers';
import { VdpExternalLink } from 'site-modules/shared/components/upper-funnel/vdp-external-link/vdp-external-link';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import {
  DEAL_TYPES,
  DEALS_BG_COLORS_STYLE,
  DEALS_IMG_ALT_TEXT,
  DEALS_TYPE_PRICE_LOGO,
  DEFAULT_PRICE,
} from 'site-modules/shared/components/deal/deal-constants';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { getPlural } from 'client/utils/plural';
import { ellipsisTruncate } from 'client/utils/string-utils';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { getPhotoConfig } from 'site-modules/shared/utils/inventory-image-utils';
import { Image } from 'site-modules/shared/components/image/image';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getNoVehicleImageToDisplay } from 'site-modules/shared/utils/get-no-vehicle-image-to-display';

const TYPES = {
  [INVENTORY_TYPES.CPO]: 'Certified',
  [INVENTORY_TYPES.USED]: 'Used',
  [INVENTORY_TYPES.NEW]: 'New',
};

function getInventoryPhoto(vinData) {
  return getPhotoConfig(vinData, 600, null);
}

export function InventoryCard({ vinData, position }) {
  const styleInfo = get(vinData, 'vehicleInfo.styleInfo', {});
  const bodyType = get(styleInfo, 'bodyType');

  const defaultPhoto = getNoVehicleImageToDisplay(bodyType);

  const { year, make, model, trim } = styleInfo;
  const vehicleName = compact([year, make, model, trim]).join(' ');

  const { photoUrls, hasPhotos } = getInventoryPhoto(vinData);
  const imageUrl = hasPhotos
    ? photoUrls[0]
    : getDefaultStockPhotoBySize(
        { make: makeNiceName(make), model: makeNiceName(model), year, submodel: get(styleInfo, 'subModels[0].niceId') },
        300,
        200
      );

  const type = get(vinData, 'type', '');

  const dealType = get(vinData, 'computedDisplayInfo.priceValidation.dealType', null);
  const dealTagStyle = {
    backgroundColor: DEALS_BG_COLORS_STYLE[dealType],
    borderRadius: '0.25rem',
    padding: '4px 4px',
  };

  const imgDeal = DEALS_TYPE_PRICE_LOGO[dealType];
  const imgDealText = DEALS_IMG_ALT_TEXT[dealType];

  const displayPrice = get(vinData, 'prices.displayPrice', 0);
  const mileage = get(vinData, 'vehicleInfo.mileage');
  const distance = get(vinData, 'dealerInfo.distance');
  const truncatedVehicleName = ellipsisTruncate(vehicleName, 28);
  const price = getPriceString(displayPrice, DEFAULT_PRICE);

  const { vin } = vinData;
  const typeId = `type-${vin}`;
  const labelId = `lnk-${vin}`;
  const dealTypeId = `deal-${vin}`;
  const priceId = `price-${vin}`;
  const describeId = `desc-${vin}`;

  return (
    <VdpExternalLink
      vinData={vinData}
      trackingValue={`${position}`}
      className="inventory-card card rounded mr-1_5 mr-md-1_75 my-0_5"
      ariaLabelledBy={`${typeId} ${labelId}`}
      ariaDescribedBy={`${dealTypeId} ${priceId} ${describeId}`}
    >
      <AspectRatioContainer aspectRatio="3:2">
        <Image
          imageId={imageUrl}
          alt=""
          errorImage={defaultPhoto}
          source={hasPhotos ? SOURCES.STATIC : SOURCES.MEDIA}
        />
      </AspectRatioContainer>
      <div className="p-0_75">
        <div className="d-flex justify-content-between mb-0_75 align-items-center first-line">
          <div className="size-10 text-primary-darker text-uppercase" id={typeId}>
            {TYPES[type.toUpperCase()]}
          </div>
          {DEAL_TYPES.includes(dealType) && (
            <div id={dealTypeId}>
              <span
                className="deal-tag text-white ml-0_5 p-0_25 size-12 rounded d-flex align-items-center"
                style={dealTagStyle}
              >
                <img width="100%" height={'11'} src={imgDeal} alt={imgDealText} decoding="async" />
              </span>
            </div>
          )}
        </div>
        <span className="visually-hidden" id={labelId}>
          {vehicleName} {price}
        </span>
        <div className="d-flex justify-content-between mb-0_75 medium fw-bold" aria-hidden>
          <div className="vehicle-name" title={truncatedVehicleName === vehicleName ? undefined : vehicleName}>
            {truncatedVehicleName}
          </div>
          <div className="text-primary-darker" id={priceId}>
            {price}
          </div>
        </div>
        <div className="d-flex justify-content-between small text-gray-dark last-line" id={describeId}>
          <div>
            {isUsed(type) && (mileage ? `${numberWithCommas(mileage)} ${getPlural('mile', mileage)}` : 'Not provided')}
          </div>
          <div>{distance && `${distance.toFixed(1)} mi away`}</div>
        </div>
      </div>
    </VdpExternalLink>
  );
}

InventoryCard.propTypes = {
  vinData: InventoryEntities.InventoryVin.isRequired,
  position: PropTypes.number.isRequired,
};
