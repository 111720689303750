import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Link } from 'site-modules/shared/components/link/link';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import edmundsLogo from 'client/images/logo-white.svg';

import './top-rated-promo-unit.scss';

const TOP_RATED_URL = '/top-rated/';
const TOP_RATED_CIRCLE_LOGO = getStaticImageUrl('/images/top-rated/2024/top-rated-2024-circle-v1.svg');
const TOP_RATED_LOGO = getStaticImageUrl('/images/top-rated/2024/top-rated-2024-v1.svg');

export function TopRatedPromoUnit({ className, isMobile }) {
  return (
    <FeatureFlag name="top-rated-promo">
      <div
        className={classnames('top-rated-promo-unit pos-r rounded', className)}
        data-tracking-parent="edm-top-rated-promo"
      >
        <img
          src={TOP_RATED_CIRCLE_LOGO}
          alt="Edmunds Top Rated Awards 2024"
          className="logo pos-a center-y hidden-md-down"
          loading="lazy"
        />
        <Row
          tag={Link}
          to={TOP_RATED_URL}
          rel="nofollow"
          data-tracking-id="view_content"
          className="promo-link pos-r no-focus text-decoration-none py-0_75 py-lg-2 px-0_5 px-md-0_75 pl-lg-3_5 pr-lg-1_5 mx-0 align-items-center justify-content-center"
        >
          <Col className="award-banner hidden-lg-up">
            <Row className="mx-0 px-md-0_75 pr-0_5 align-items-center">
              <Col xs={12} md="auto" className="text-center px-0">
                <img src={edmundsLogo} alt="" className="edm-logo" />
              </Col>
              <Col xs={12} md="auto" className="separator mx-0_75 px-0 hidden-sm-down" />
              <Col xs={12} md="" className="px-0 text-center">
                <img src={TOP_RATED_LOGO} alt="" className="logo-2024" />
              </Col>
            </Row>
          </Col>
          <Col className="award-text text-white ml-3_5 pl-3_5 text-center hidden-md-down">
            THE SIX BEST VEHICLES OF 2024
          </Col>
          <Col
            xs="auto"
            className={classnames(
              'pos-r overflow-hidden btn text-cool-gray-100 text-shadow-contrast border-0 px-1_25 px-md-2_5 py-0_5 py-md-0_75',
              {
                'size-16 fw-bold': !isMobile,
                'small fw-medium': isMobile,
              }
            )}
          >
            See <span className="hidden-sm-down">the</span> Winners <span className="hidden-md-up">Now</span>
          </Col>
        </Row>
      </div>
    </FeatureFlag>
  );
}

TopRatedPromoUnit.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

TopRatedPromoUnit.defaultProps = {
  className: null,
  isMobile: false,
};
