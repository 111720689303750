import { get } from 'lodash';
import {
  TITLE_ISSUE,
  USAGE_TYPE,
  VEHICLE_HISTORY_POINTS_EXPERIAN,
} from 'client/site-modules/shared/components/inventory/helpers/vehicle-history-helper';

function getAccidentCopy(accidentText) {
  if (!accidentText) return '';

  switch (accidentText) {
    case '0':
      return 'No accidents';
    case '1':
      return '1 Accident';
    default:
      return `${accidentText} Accidents`;
  }
}

export function getIconClasses({ accidentText, cleanTitle, isGrayIcons }) {
  const checkmarkClass = isGrayIcons ? 'icon-checkmark text-gray-darker' : 'icon-checkmark text-primary-darker';
  return accidentText > 0 || cleanTitle === false ? 'icon-warning2' : checkmarkClass;
}

export function getTitleDisplayDescription(historyInfo) {
  const titleDescription = get(historyInfo, 'titleDescription');
  const accidentText = get(historyInfo, 'accidentText');
  const cleanTitle = get(historyInfo, 'cleanTitle');

  if (cleanTitle === false) {
    return titleDescription || TITLE_ISSUE;
  }
  return getAccidentCopy(accidentText);
}

export function getOwnerDisplayDescription(historyInfo) {
  const ownersNumber = get(historyInfo, 'ownerText');
  if (!ownersNumber) return '';
  if (ownersNumber === '1') return '1 Owner';
  return `${ownersNumber} Owners`;
}

export function getUsageTypeDisplayDescription(historyInfo, isSrpExpanded) {
  let usageType = get(historyInfo, 'usageType');
  if (usageType === 'Lease' && isSrpExpanded) {
    usageType = 'Lease_SRP_expanded';
  }
  const text = usageType && get(VEHICLE_HISTORY_POINTS_EXPERIAN[USAGE_TYPE][usageType], 'content');
  return text || '';
}

export function getRecurrentRangeValue(vehicle) {
  const expectedRangeMin = vehicle?.thirdPartyInfo?.recurrentInsights?.expectedRange?.min;
  const expectedRangeMax = vehicle?.thirdPartyInfo?.recurrentInsights?.expectedRange?.max;

  if (!expectedRangeMin && !expectedRangeMax) {
    return '';
  }

  return expectedRangeMin === expectedRangeMax
    ? `${expectedRangeMin} mi est. range`
    : `${expectedRangeMin} - ${expectedRangeMax} mi est. range`;
}

export function getRecurrentScoreValue(vehicle) {
  const rangeScoreDescriptor = vehicle?.thirdPartyInfo?.recurrentInsights?.rangeScoreDescriptor;

  return rangeScoreDescriptor ? `${rangeScoreDescriptor} battery score` : '';
}
