import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import classNames from 'classnames';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import './seo-links.scss';

/**
 * Single item: seo link
 */
function SeoLinkItem({ href, title }) {
  return (
    <li>
      <a className="size-14" href={href} data-tracking-id="view_seo_internal_link">
        {title}
      </a>
    </li>
  );
}
SeoLinkItem.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

/**
 * List of links in seo links section
 */
function SeoLinkList({ items, className, labelledbyName }) {
  return (
    <ul className={classNames('list-unstyled small', className)} aria-labelledby={labelledbyName}>
      {items.map(item => (
        <SeoLinkItem href={item.href} title={item.title || item.anchorText} key={item.href} />
      ))}
    </ul>
  );
}

SeoLinkList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelledbyName: PropTypes.string,
  className: PropTypes.string,
};

SeoLinkList.defaultProps = {
  className: '',
  labelledbyName: null,
};

function SeoLinkSectionTitle({ title, id }) {
  return title ? (
    <h3 className="fw-bold size-14 mb-0" id={id}>
      {title}
    </h3>
  ) : null;
}

SeoLinkSectionTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
};

SeoLinkSectionTitle.defaultProps = {
  id: null,
  title: '',
};

/**
 * Seo links entire section (+ header)
 */
export function SeoLinkSection({ content }) {
  const title = content.metadata('title').value();
  const items = content.links();
  const id = title.replace(/\s/g, '-');

  return (
    <Fragment>
      <SeoLinkSectionTitle title={title} id={id} />
      <SeoLinkList items={items} labelledbyName={id} />
    </Fragment>
  );
}

SeoLinkSection.propTypes = {
  content: CmsEntities.Content.isRequired,
};

function LatestCarNewsSectionUI({ content }) {
  return (
    <Fragment>
      <h2 className="pb-1 text-start heading-3 mt-1_5">Latest car news</h2>
      <div className="latest-car-news-section seo-column-count">
        <SeoLinkSection content={content.child('pre-prod-list')} />
      </div>
    </Fragment>
  );
}

LatestCarNewsSectionUI.propTypes = {
  content: CmsEntities.Content,
};

LatestCarNewsSectionUI.defaultProps = {
  content: DEFAULT_CONTENT,
};

export const stateToPropsConfig = {
  content: bindToContent('editorial/includes/venom/home-page-pre-prod-list', 'pre-prods'),
};

export const LatestCarNewsSection = connectToModel(LatestCarNewsSectionUI, stateToPropsConfig);

export function SeoLinks({ content, className, btnClassName, isHomepage, minHeight }) {
  return (
    <Container
      className={classNames('seo-links link-widget text-gray-darker px-0_5', className)}
      data-tracking-parent="edm-entry-internal-links"
      tag="section"
    >
      <h2 className="text-capitalize pb-1 text-start heading-3">
        {getTitleWithNoOrphans('What shoppers are searching for')}
      </h2>
      <ContentCollapse
        minHeight={`${minHeight}px`}
        isEclipseFade={false}
        textClosed="See more"
        textOpen="See less"
        showBtnBelow
        justify="justify-content-start"
        btnClasses={btnClassName}
        btnTextClasses="fw-normal text-primary-darker size-14"
        btnArrowClasses="size-10 text-primary-darker"
        btnSize="md"
        ariaLabelHeading="about what shoppers are searching for"
      >
        <div className="seo-column-count">
          <SeoLinkSection content={content.child('popular-new-cars-seo-footer')} />
          <SeoLinkSection content={content.child('popular-sedans-seo-footer')} />
          <SeoLinkSection content={content.child('popular-suvs-seo-footer')} />
          <SeoLinkSection content={content.child('popular-pickup-truck-seo-footer')} />
          <SeoLinkSection content={content.child('popular-minivans-seo-footer')} />
          <SeoLinkSection content={content.child('popular-hatchback-seo-footer')} />
          <SeoLinkSection content={content.child('popular-crossovers-seo-footer')} />
          <SeoLinkSection content={content.child('popular-convertibles-seo-footer')} />
          <SeoLinkSection content={content.child('popular-coupes-seo-footer')} />
          {isHomepage && <SeoLinkSection content={content.child('homepage-eval-seo-footer')} />}
        </div>
        {isHomepage && <LatestCarNewsSection />}
      </ContentCollapse>
    </Container>
  );
}

SeoLinks.propTypes = {
  content: CmsEntities.Content,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  isHomepage: PropTypes.bool,
  minHeight: PropTypes.number,
};

SeoLinks.defaultProps = {
  content: DEFAULT_CONTENT,
  className: '',
  btnClassName: 'pl-0_5',
  isHomepage: false,
  minHeight: 300,
};
