import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';

import { CmsEntities } from 'client/data/models/cms';
import { getFullImageUrl } from 'client/utils/image-helpers';
import { Link } from 'site-modules/shared/components/link/link';
import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

import './buying-guide-module.scss';

export function BuyingGuideModule({ className, isMobile, content }) {
  if (!content.hasMetadata('headline')) {
    return null;
  }
  const headline = content.metadata('headline').value();
  const bodyText = content.metadata('body-text').value();
  const imageSrc = getFullImageUrl(content.metadata('image').value(), IMAGE_SIZES.sm);
  const linkText = content.metadata('link-text').value();
  const linkUrl = content.metadata('link-url').value();

  return (
    <aside
      className={classnames('buying-guide-module', className)}
      aria-labelledby="buying-guide-container-label"
      data-tracking-parent="buying_guide_module"
    >
      <div id="buying-guide-container" className="buying-guide-container rounded p-1_5 pos-r bg-white">
        <div className="badge text-white badge-success size-12 fw-bold px-0_5 py-0_25">
          <i className="icon-fire mr-0_5" aria-hidden />
          Edmunds Recommends
        </div>
        <Row>
          {!isMobile && (
            <Col md={3}>
              <AspectRatioContainer aspectRatio="16:9">
                <img className="w-100" decoding="async" src={imageSrc} alt="" />
              </AspectRatioContainer>
            </Col>
          )}
          <Col xs={12} md={9} className="pl-md-1">
            <div id="buying-guide-container-label" className="heading-4 mb-0_25 text-gray-darker">
              {headline}
            </div>
            <p className="display-lg mb-1">{bodyText}</p>
            {linkUrl && (
              <Link to={linkUrl} className="text-primary-darker" data-tracking-id="buying_guide_module_cta">
                {linkText} <span className="icon-arrow-right3 size-10" aria-hidden />
              </Link>
            )}
          </Col>
        </Row>
      </div>
    </aside>
  );
}

BuyingGuideModule.propTypes = {
  content: CmsEntities.Content.isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

BuyingGuideModule.defaultProps = {
  className: '',
  isMobile: false,
};
